.multi-line-header {
  white-space: normal !important; /* Allow text wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center; /* Optional: Center align text */
  padding: 10px; /* Add padding for better readability */
  max-width: 110px; /* Prevent excessive width */
  height: auto; /* Allow dynamic height based on content */
}

.ctooltip {
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.ctooltip-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;

  position: absolute;
  left: 50%;
  bottom: 120%;
  transform: translateX(-50%);
  white-space: nowrap;
  
  opacity: 0;
  transition: opacity 0.3s;
}

.ctooltip:hover .ctooltip-text {
  visibility: visible;
  opacity: 1;
}